.tag-box {
  padding-top: 3%;

  &__tag {
    margin: 1%;
    font-size: 12px;
    border-radius: 16px;
    text-align: center;
    color: #818181;
    border-color: #f5f5f5;
  }
}

.ant-select-selection-search {
  width: 99% !important;
  display: block;
}
