@mixin animated($time) {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: $time;
  -moz-animation-duration: $time;
  -ms-animation-duration: $time;
  animation-duration: $time;
}

@keyframes fade-in {
  from {
    transform: translate3d(0, 10px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  from {
    transform: translate3d(0, 10px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  /* Firefox */
  from {
    transform: translate3d(0, 10px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-o-keyframes fade-in {
  /* Opera */
  from {
    transform: translate3d(0, 10px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@mixin fadeIn($time) {
  @include animated($time);
  -webkit-animation-name: fade-in;
  -moz-animation-name: fade-in;
  animation-name: fade-in;
}
