.modal {
  &--filter {
    &__title {
      h4 {
        margin-bottom: 0;
      }
      p {
        font-weight: normal;
        font-size: 14px;
      }
    }
  }
}
