.filters-sidebar {
  &__content {
    .ant-drawer-content-wrapper {
      width: 370px !important;

      @media screen and (max-width: $screen-xs) {
        width: 100% !important;
      }
    }

    .ant-drawer-header-close-only {
      position: absolute;
      right: 0;
      border: unset;
    }

    .ant-drawer-body {
      padding: 0;
      overflow: inherit;
    }

    .filters-sidebar {
      &__header {
        padding: 15px 50px 15px 25px;
        background-color: #f5f5f5;
        top: unset;
        z-index: 1051;

        a {
          color: #0085de;
        }

        *::selection {
          color: #fff;
          background-color: $primary-2;
        }
      }

      &__counter {
        display: flex;
        flex-direction: column;
      }

      &__filters-counter {
        font-size: 13px;
        font-weight: 300;
        margin-bottom: 3px;

        strong {
          font-weight: 700;
        }
      }

      &__candidates-counter {
        font-size: 13px;
        font-weight: 300;

        strong {
          font-weight: 700;
          padding-right: 5px;
        }
      }

      @keyframes shine {
        to {
          background-position-x: -200%;
        }
      }

      @mixin skeleton {
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: .8s shine linear infinite;
      }

      &__filters-counter-skeleton {
        height: 19.5px;
        display: flex;
        margin-bottom: 0.5px;
        .status {
          height: 19.5px;
          width: 55%;
          margin-right: 5px;
          @include skeleton;
        }
        .option {
          height: 19.5px;
          width: 40%;
          @include skeleton;
        }
      }

      &__candidates-counter-skeleton {
        height: 19.5px;
        width: 40%;
        margin-top: 0.5px;
        @include skeleton;
      }

      &__filter {
        padding: 15px 20px;

        .ant-select-selector {
          height: 40px;
          align-items: center;

          .ant-select-selection-item {
            display: flex;
            align-items: center;
          }
        }

        .ant-select:not(.ant-select-disabled):hover {
          .ant-select-selector {
            border-color: $primary-2;
          }
        }

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
          .ant-select-selector {
            border-color: $primary-2;
            box-shadow: 0 0 0 2px rgba(88, 82, 255, 0.2);
          }
        }

        .ant-input-number:hover {
          border-color: $primary-2;
        }

        .ant-input-number-focused {
          border-color: $primary-2;
          box-shadow: 0 0 0 2px rgba(88, 82, 255, 0.2);
        }

        .filters-title {
          font-weight: 700;
        }

        &--geo-point {
          height: 48px;
          font-size: 14px;
          line-height: 1;
          display: grid;
          grid-template-columns: 15% 80%;
          grid-template-rows: 35px;
          gap: 0 0;
          grid-template-areas: ". .";

          .las {
            font-size: 2em;
            margin-top: auto;
            margin-bottom: auto;
            text-align: right;
          }
          span {
            margin: auto;
          }
        }

        &--item {
          background-color: #ffffff;
          color: #363b3f;
          font-size: 13px;
          line-height: 1.5;
          font-weight: 400;
          cursor: pointer;

          &:hover {
            color: #000000;
            font-weight: 700;
            @media screen and (max-width: 992px) {
              font-weight: 400;
              opacity: 1;
            }
          }

          &.ant-tag-checkable-checked {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #efefef;
            color: #000000;
            font-weight: 700;
            border-radius: 50px;
            border: 1px solid #d1d1d1;
            padding: 0 10px;
            min-height: 28px;

            i {
              margin-left: 5px;
            }
          }

          &.block {
            cursor: no-drop;

            span {
              pointer-events: none;
            }
          }
        }

        &--section {
          .section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &__body {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              &.block {
                cursor: no-drop;

                span {
                  color: #bfbfbf;
                  pointer-events: none;
                }
              }
            }

            .ant-tag {
              margin-bottom: 3px;
            }

            .ant-input-number {
              width: 7.5rem;
            }
          }
          &-input {
            text-transform: lowercase;
          }
        }

        &--select {
          width: 100%;
          font-weight: 600;

          &__label {
            white-space: normal;

            &__counter {
              color: #989898;
            }
          }
        }
      }

      &__button {
        border-radius: 0;
        position: fixed;
        height: 3.125rem;
        z-index: 1051;
        bottom: 0;
        width: 367px;
      }

      &__button.save {
        background: #13223a;
        border-color: #13223a;
      }
    }

    &__jobs-filters {
      .ant-btn {
        color: $contrast-color;
        border-color: $contrast-color;

        &:hover,
        &:focus {
          color: #fff;
          border-color: $contrast-color;
          background-color: $contrast-color;
        }
      }

      &:last-child {
        margin-bottom: 2rem;
      }

      *::selection {
        color: #fff;
        background-color: $primary-2;
      }
    }
  }
}

.alert-message {
  color: #f70029;
  font-size: smaller;
}
