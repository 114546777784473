.custom-range {
  &.ant-form {
    width: 100%;
  }

  .ant-picker-range {
    height: 40px;
    width: 100%;
  }

  .ant-form-item-explain-error {
    display: none;
  }
  .ant-form-item-with-help {
    margin-bottom: 0;
  }
  .removeText {
    color: $information;
  }
  .ant-form-item-control-input {
    min-height: 40px;
  }
  .ant-input-number-input {
    height: 40px;
  }
  .ant-btn-circle {
    border-color: $contrast-color;
    &:hover {
      background-color: $contrast-color;
      color: $white;
    }
  }
  &-dropdown {
    .ant-picker-cell-range-start {
      &::before {
        background: rgba($color: $primary-light1, $alpha: 0.1) !important;
      }
    }
    .ant-picker-cell-range-end {
      &::before {
        background: rgba($color: $primary-light1, $alpha: 0.1) !important;
      }
    }
    .ant-picker-cell-range-start,
    .ant-picker-cell-range-end {
      .ant-picker-cell-inner {
        border-radius: 50% !important;
        &::before {
          border-color: $primary-light1;
          border-radius: 50%;
        }
      }
    }
    .ant-picker-cell-in-range.ant-picker-cell-in-view {
      &::before {
        background: rgba($color: $primary-light1, $alpha: 0.1);
      }
    }
    .ant-picker-cell.ant-picker-cell-today,
    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        min-width: 30px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before {
          border-color: $primary-light1;
          border-radius: 50%;
        }
      }
    }
    .ant-picker-content {
      th {
        color: $primary-light1;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
    @media screen and (max-width: $screen-md) {
      position: relative;
      &-title {
        position: relative;
      }
      &-clear {
        position: absolute;
        top: 4px;
        right: 0.6rem;
        i {
          font-size: 1.25rem;
          color: rgba($color: $negative, $alpha: 0.4);
        }
      }
      .ant-picker-range-wrapper {
        min-width: auto !important;
        min-height: 100%;
      }
      .ant-picker-panel-container {
        margin: 0;
        overflow: hidden scroll;
        height: calc(100vh + 1rem);
      }

      .ant-picker-panels {
        margin-bottom: 2rem;
        margin-top: -2rem;
      }

      .ant-picker-panels,
      .ant-picker-time-panel {
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
      }

      .ant-picker-range-arrow {
        display: none;
      }
      .ant-picker-year-panel,
      .ant-picker-month-panel {
        width: 100%;
        border: none;
        .ant-picker-header {
          top: 0 !important;
          left: 0 !important;
        }
      }
      .ant-picker-dropdown {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0 !important;
        left: 0 !important;
        overflow-y: auto;
      }

      .ant-picker-panel {
        width: 100%;
        margin: auto;
        border: none;
        .ant-picker-date-panel {
          width: 100%;
        }
        .ant-picker-body {
          min-height: 20.5rem;
          .ant-picker-content {
            width: 98%;
            min-height: 100%;
            .ant-picker-cell {
              padding: 10px 0;
              white-space: nowrap;
              &::before {
                height: 36px;
              }
            }

            .ant-picker-cell.ant-picker-cell-selected,
            .ant-picker-cell-start,
            .ant-picker-cell-range-start,
            .ant-picker-cell-range-end {
              padding: 5px 0;
              .ant-picker-cell-inner {
                width: 100%;
                height: 36px;
                justify-content: center;
                display: flex;
                align-items: center;
              }
            }
          }
        }
        &:first-child {
          .ant-picker-header {
            position: fixed;
            height: 3rem;
            display: flex;
            top: 0;
            left: 0;
            width: 100%;
            align-items: center;
            z-index: 9999999;
            background: white;
            .ant-picker-header-next-btn,
            .ant-picker-header-super-next-btn {
              visibility: visible !important;
              display: block !important;
            }
          }
          .ant-picker-body {
            margin-top: 3rem;
          }
        }
        &:last-child {
          margin-top: 1rem;
          .ant-picker-header {
            left: 50%;
            width: 100%;
            right: unset;
            top: 50%;
            .ant-picker-header-super-next-btn,
            .ant-picker-header-next-btn,
            .ant-picker-header-super-prev-btn,
            .ant-picker-header-prev-btn {
              visibility: hidden;
              display: none;
            }
          }
        }
        .ant-picker-year-panel {
          .ant-picker-header-super-prev-btn,
          .ant-picker-header-super-next-btn {
            visibility: visible !important;
            display: block !important;
          }
        }
      }
      .ant-picker-footer {
        position: fixed;
        bottom: 0;
        min-height: 5rem;
        left: 0;
        z-index: 999999999999;
        width: 100vw;
        .ant-picker-footer-extra {
          .footer-confirm {
            width: 100vw;
          }
        }
      }
    }
    &.mobile {
      @media screen and (max-width: $screen-sm) {
        .datePicker__calendar {
          width: calc(100vw - 2rem);
        }
      }
      @media screen and (max-height: $screen-lg) {
        .ant-picker-range-wrapper {
          height: calc(100vh + 2rem);
        }
      }
      @media screen and (max-height: $screen-md) {
        .ant-picker-range-wrapper {
          height: calc(100vh + 35%);
        }
      }
      @media screen and (max-height: $screen-sm) {
        .ant-picker-range-wrapper {
          height: calc(100vh + 55%);
        }
      }
    }
  }
}
